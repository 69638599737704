import { createStore } from "vuex";

export default createStore({
    state() { return { loginStatus: false } },
    mutations: {
        logout(state) {
            state.loginStatus = false
        },
        login(state) {
            state.loginStatus = true
        }
    }
})