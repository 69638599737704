import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    { path: "/", redirect: "/home" },
    {
        path: "/layout",
        name: "layout",
        component: () =>
            import("../views/layout/layoutFrame"),
        children: [{
            path: "/home",
            name: "home",
            component: () =>
                import("../views/homeIndex"),
        },
        {
            path: "/projectList",
            name: "projectList",
            component: () =>
                import("../views/project/projectList"),
        },
        {
            path: "/taskList",
            name: "taskList",
            component: () =>
                import("../views/task/taskList"),
        },
        {
            path: "/newsList",
            name: "newsList",
            component: () =>
                import("../views/news/newsList"),
        },
        {
            path: "/chooseBatchList",
            name: "chooseBatchList",
            component: () =>
                import("../views/chooseBatch/chooseBatchList"),
        },
        {
            path: "/caseList",
            name: "caseList",
            component: () =>
                import("../views/case/caseList"),
        },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import("../views/layout/loginIndex")
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router